<template>
  <div class="founderAddress">
      <Head @closeMenu="closeOpenMenu" />
       <Menu ref="menu" />
        <Topreturn @closeMenu="closeOpenMenu"></Topreturn>
      <div class="banner">
                <!-- <div class="forward">
          </div> -->
      </div>
      <div class="contents" id="contents">
          <div class="contentCenter">
              <h3 class="title" id="title">创始人致辞</h3>
              <p class="lines">
                <span class="lineCenter"></span>
              </p>

              <div class="contextCenter">
                <div class="left"></div>
                <div class="right">
                   <h2 class="titles">祥业董事长致辞</h2>
                   <p class="textContent">
                         我们基于智能、云、微服务构建新的体系和标准，重新赋能数字化、智能化经济时代的基础设施建设，开放生态，让更多伙伴和社会资源一起繁荣发展，包括数据智能、自动驾驶、云计算、芯片、存储、安全、视觉、语言、AI、云端一体、大数据，共同谱写新的科技产业篇章！充分实现技术产业化！技术产品国产化！
                        <br>
                           集团层面专注于信息技术创新生态开发与运营、商业管理与运营、资产管理托管服务三大业务板块的深耕细作，赋能新基建，致力成为中国专业的科技创新产业运营服务商。
                        <br>
                          生生不息的企业文化是集团持续成长的根基。我们将始终秉承“以客户为中心、以奋斗者为本、以价值为纲”的价值观。以“做一家让我们骄傲的公司”为使命，形成了“真诚 、奋斗、专业、创新、协作、奉献”的企业精神。在艰苦的创业时期，祥业科技控股集团践行人才优先发展的策略，集聚人才、用好人才、培养人才，努力把集团建设成为人才创业的科技平台，这也是集团所有人共同的梦想与追求。
                          <br>
                          我相信，企业因梦想而卓越，尽管前方任重而道远；我也相信，有我们用汗水与智慧搭建的事业基石，我们在发展的征程上，有充足的信心，步伐更加坚定，也必将以优异的经营业绩回报员工、股东和社会！
                   </p>
                    <div class="EnName"></div>
                </div>
              </div>
          </div>
      </div>
        <Footer />
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Footer from "@/components/footer.vue";
import Menu from "@/components/menu.vue";
import Topreturn from "@/components/Topreturn.vue";
import setScrollTop from "../../utils/setScrollTop.js";
export default {
 components: {
    Head,
    Menu,
    Footer,
    Topreturn
  },
    methods: {
    closeOpenMenu(t) {
      console.log(t);
      this.$refs.menu.menuShow = t;
    },
  },
  watch: {
    $route(to, from) {
      setScrollTop('title');
    },
  },
  mounted(){
    setScrollTop('title');
  },

}

</script>

<style scoped>
/* banner图开始 */
 .banner{
     width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .banner .forward{
    width: 164px;
    height: 44px;
    background-image: url("../../assets/image/aboutGroup/index/BnnerContext.png");
     background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 58px;
    bottom: 26px;
 }
 /* banner图结束 */

 /* 主体内容开始 */
 .contents{
    width: 100%;
    height: 1080px;
    background-image: url("../../assets/image/aboutGroup/index/backgroundImage.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
 }
 .contents .contentCenter{
    padding-top: 78px;
    color: #fff;
    max-width: 1466px;
    margin: 0 auto;
 }
 .contents .contentCenter .title{
   font-size: 40px;
   font-family: IOS9W4;
   font-weight: bold;
   color: #17171C;
 }
 .contents .contentCenter .lines{
   margin-bottom: 36px;
 }
 .contents .contentCenter .lines .lineCenter{
    width: 70px;
    height: 4px;
    background: #3C4047;
    border-radius: 2px;
    display: inline-block;
 }
 .contents .contentCenter .contextCenter{
    color: #333333;
    display: flex;
    padding-top: 37px;
    justify-content: space-between;
 }
 .contents .contentCenter .contextCenter .left{
    width: 577px;
    text-align: left;
    display: inline-block;
    background-image: url("../../assets/image/aboutGroup/founderAddress/content.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-family: Microsoft YaHei;
    overflow: hidden;
    height: 796px;
 }
 .contents .contentCenter .contextCenter .right{
    width: 850px;
    display: inline-block;
    text-align: left;
 }
 .contents .contentCenter .contextCenter .right .titles{
   font-size: 50px;
   font-family: IOS9W4;
   font-weight: 400;
   color: #000508;
 }
  .contents .contentCenter .contextCenter .right .textContent{
    padding-top: 27px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 43px;
    height: 627px;
    font-size: 20px;
  }
  .contents .contentCenter .contextCenter .right .EnName{
    width: 739px;
    height: 58px;
    background-image: url("../../assets/image/aboutGroup/founderAddress/EnName.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: 26px;
  }

  /* hover 事件 */
 .contents .contentCenter .contextCenter .left:hover {
    background-size: 105% 105%;
    transition: 0.5s;
}
</style>
